import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import comicSansFont from '../../fonts/Handlee-Regular.ttf';

const MyPDF = ({companyName, reduction}) => {
  useEffect(() => {
    Font.register({ family: "comicSansO", src: comicSansFont });
  }, []);
  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
        // orientation="portrait"
      >
        <View style={styles.section}>
          <Image
            source="https://res.cloudinary.com/thewebplug/image/upload/v1711362076/Picture1_ncx3v8.jpg"
            style={styles.image}
          />
          <Text style={styles.h1}>Netzence Certificate of</Text>
          <Text style={[styles.h1, styles.h1btm]}>Carbon Reduction</Text>
          <Text style={styles.h2}>
            {companyName}, {reduction} CloseCarbon Reduction Tonnes
          </Text>
          <Text style={styles.h3}>
            This certifies that the company, {companyName}, has
            reduced {reduction} Tonnes <br /> of carbon dioxide.
             {/* on 7 November 2023. */}
          </Text>
          <View style={styles.signatureSection}>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureItemH3}>Reduced by</Text>
              <Text style={styles.signatureSignature}>{companyName}</Text>
              <Text style={styles.signatureItemH3}>{companyName}</Text>
            </View>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureItemH3}>Verified by</Text>
              <Text style={styles.signatureSignature}>Climate Certifications Ltd</Text>
              <Text style={styles.signatureItemH3}>Climate Certifications Ltd</Text>
            </View>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureItemH3}>Issued by</Text>
              <Text style={styles.signatureSignature}>Netzence</Text>
              <Text style={styles.signatureItemH3}>Netzence</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  section: {
    margin: 10,
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #000",
    width: "100%",
  },
  image: {
    width: "100px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "green",
    marginBottom: "20px",
  },
  h1: {
    fontSize: "36px",
    textAlign: "center",
    fontWeight: "900",
    // fontFamily: "Arial"
  },
  h1btm: {
    marginBottom: "25px",
  },
  h2: {
    fontSize: "21px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "28px",
  },
  h3: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "25px",
  },
  signatureSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "30px",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  signatureItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: 1, // Add this line
  },
  signatureItemH3: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "600",
  },
  signatureSignature: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "400",
    marginTop: "25px",
    marginBottom: "5px",
    paddingBottom: "15px",
    borderBottom: "1px solid #000",
    width: "100%",
    fontFamily: "comicSansO"
  },
});

export default MyPDF;
